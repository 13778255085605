import { MAccount, MApp, MDocument } from ".";

export class MCampaign {
  id?: number = 0;
  applicationKey?: string;
  key: string = "";
  name: string = "";
  budget?: number;
  spendDay?: number;
  trackingLinkImpression: string = "";
  trackingLinkClick: string = "";
  countries: MCampaignCountry[] = [];
  impressions: number = 0;
  clicks: number = 0;
  installs: number = 0;
  ecpi: number = 0;
  realEcpi: number = 0;
  ecpm: number = 0;
  realEcpm: number = 0;
  spend: number = 0;
  realSpend: number = 0;
  ipm: number = 0;
  ir: number = 0;
  isActive?: boolean;
  isValide?: boolean;
  createdAt?: Date;
  previewStatus?: { status: 'valid' | 'warning' | 'error' | null , message: string[] } | null;
  //
  Application: MApp = new MApp();
  Account?: MAccount;
  Videos: MCampaignAsset[] = [];
  Images: MCampaignAsset[] = [];
  Endcards: MCampaignAsset[] = [];
  Playables: MCampaignAsset[] = [];
  Banners: MCampaignAsset[] = [];
}

export class MCampaignAsset {

  constructor(doc?: MDocument) {
    if (doc) {
      this.Document = doc;
    }
  }

  id?: number = 0;
  campaignId?: number = 0;
  documentId?: number = 0;
  type?: string = "";
  spends: number = 0;
  nbImpressions: number = 0;
  nbClicks: number = 0;
  nbInstalls: number = 0;
  isActive?: boolean = true;
  isValide?: boolean | null;
  createdAt: Date = new Date();
  Document?: MDocument;
  Campaign?: MCampaign;
}

export class MCampaignCountry {
  key: string = "";
  cpi: number = 0;
  isActive: boolean = true;
  error?: string | null;
}

export class MCampaignAd {
  id: number = 0;
  CampaignAssetVideo: MCampaignAsset = new MCampaignAsset();
  CampaignAssetEndCard: MCampaignAsset = new MCampaignAsset();
  impressions: number = 0;
  clicks: number = 0;
  installs: number = 0;
  spend: number = 0;
  realspend: number = 0;
  ipm: number = 0;
  ir: number = 0;
  ecpi: number = 0;
  realEcpi: number = 0;
  ecpm: number = 0;
  realEcpm: number = 0;
  isActive: boolean = true;
}