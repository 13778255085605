import { BaseService } from "./base.service";
import { UserAuth } from "../contexts/auths.context";

class AuthsService extends BaseService {

  PATH = "auths/";
  realToken: string = '';

  async signup(data: any): Promise<UserAuth | null> {
    const login = await this.post(this.PATH + "signup", data);
    if (login?.token) {
      this.setToken('user-token', login.token);
      return login.user;
    }
    return null;
  }

  async login(data: any): Promise<UserAuth | null> {
    const login = await this.post(this.PATH + "login", data);
    if (login?.token) {
      this.setToken('user-token', login.token);
      return login.user;
    }
    return null;
  }

  async loginAs(userId: number): Promise<UserAuth | null> {
    this.realToken = this.getToken('user-token');
    this.setToken('real-token', this.getToken('user-token'));
    const login = await this.post(this.PATH + "login-as/" + userId, {});
    if (login?.token) {
      this.setToken('user-token', login.token);
      return login.user;
    }
    return null;
  }

  logoutAs(): void {
    this.setToken('user-token', this.realToken);
    this.realToken = '';
  }

  async forgetPassword(data: any): Promise<boolean> {
    if (await this.post(this.PATH + "forget-password", data)) {
      return true;
    }
    return false;
  }
  async resetPassword(data: any): Promise<boolean> {
    if (await this.post(this.PATH + "reset-password", data)) {
      return true;
    }
    return false;
  }

  async autologin(): Promise<UserAuth | null> {
    
    if (!!this.getToken('user-token')) {
      const login = await this.get(this.PATH + "autologin", {});
      if (login?.token) {
        this.setToken('user-token', login.token);
        if(!!this.getToken('real-token')) {
          this.realToken = this.getToken('real-token');
          this.delToken('real-token');
        }
        return login.user;
      }
    }
    return null;
  }

  async logout(): Promise<boolean> {
    await this.get(this.PATH + "logout", {});
    this.delToken('user-token');
    return true;
  }

}

export const authsService = new AuthsService();
