import { useState } from 'react';
import { Button, QRCode } from 'antd-v5';

import { MCampaign } from '@/modeles';
import { campaignsService } from '@/services';

import './review-install.scss';


export function YcReviewInstall(props: {
  campaign: MCampaign
}) {

  const [isCheckingInstall, setIsCheckingInstall] = useState<boolean>(false)
  const [installValidate, setInstallValidate] = useState<boolean | null>(null)

  const checkInstall = async () => {
    setIsCheckingInstall(true);
    setInstallValidate(await campaignsService.campaignInstallValidation(props.campaign.key));
    setIsCheckingInstall(false);
  }

  return (
    <div id='review-install'>
      <div className='content'>
        <div>
          <QRCode
            errorLevel="M"
            type="svg"
            value={props.campaign.Application.platform === 'android'
              ? 'https://play.google.com/store/apps/details?id=com.YsoCorp.MinecubeIdle'
              : 'https://apps.apple.com/ch/app/octohits/id1441445569?l'}
            size={200}
          />
        </div>
        <div>
          <ul>
            <li>Scan the QR code with your mobile device.</li>
            <li>Install the app.</li>
            <li>Run a test ad in the app:</li>
            <ul>
              <li>Go to the app's settings.</li>
              <li>Click multiple time on the SDK version.</li>
              <li>Scroll down to "YSO Network".</li>
              <li>Enable test mode (restart the app after enabling).</li>
              <li>Repeat steps 1 to 3, then click on test mode again to access the test environment.</li>
              <li>Click "Load Interstitial", then click "Show" once it has finished loading.</li>
              <li>Play the ad to be redirected to the store and download the app.</li>
              <li>Play the app for a bit until an ad appears.</li>
            </ul>
            <li>Return to this page and click the verification button.</li>
          </ul>
          <div className='validation-button'>
            <Button type='primary' loading={isCheckingInstall} onClick={checkInstall}>Check install</Button>
            {
              installValidate === true &&
              <p className='validation-text valid'>Installation validated !</p>
            }
            {
              installValidate === false &&
              <p className='validation-text unvalid'>No installation detected !</p>
            }
          </div>
        </div>
      </div>

    </div>
  );
}