import { ReloadOutlined } from '@ant-design/icons';
import { Button, Radio, Spin } from 'antd';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { ReportLineGraph, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { MControlPanel } from '@/modeles';
import { controlPanelService_SA } from '@/services';

export function SAControlPanelPage() {
  usePageTitle('Control panel Global');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MControlPanel>(new MControlPanel());
  const [mode, setMode] = useState<'7d' | '30d' | '90d'>('90d');
  const height = 232;

  const maxDiff = useMemo(() => {
    const max = data.maxYsoRevenue;
    const yn = data.ynYsoRevenue;

    return max.map((maxRow) => ({
      date: maxRow.date,
      diffPercent: (((yn.find((ynRow) => moment.utc(maxRow.date).isSame(moment.utc(ynRow.date)))?.monet || 0) / maxRow.revenue) - 1) * 100,
    }))
  }, [data])

  const fetchData = useCallback(() => {
    setLoading(true);
    controlPanelService_SA.view(mode).then((data) => {
      setData(data || new MControlPanel());
    }).finally(() => {
      setLoading(false);
    });
  }, [mode]);

  useEffect(() => { fetchData() }, [fetchData]);

  return (
    <Spin spinning={loading}>
      <div className='d-flex'>
        <YcTitle label='Control Panel Global'/>
        <Button className="mx-3" type="primary" onClick={() => fetchData()}>
          <ReloadOutlined/> Reload
        </Button>
      </div>
      <div className="mb-4">
        <Radio.Group onChange={(v) => setMode(v.target.value)} value={mode} optionType='button' buttonStyle="solid">
          <Radio value={'7d'}>7 days</Radio>
          <Radio value={'30d'}>30 days</Radio>
          <Radio value={'90d'}>90 days</Radio>
        </Radio.Group>
      </div>
      <div id="admin-control-panel">
        <div className="meta-card">
          <div className="meta-card-title">Customer</div>
          <div className="meta-card-content">
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA</div>
              <ReportLineGraph data={data.ynCustomerUA} is={['account']} fields={['spend']} height={height} limit={11}/>
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">Monet</div>
              <ReportLineGraph data={data.ynCustomerMonet} is={['account']} fields={['revenue']} height={height} limit={11}/>
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA - Monet</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'rCustomer', 'diffYso']} height={height}/>
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">Global</div>
          <div className="meta-card-content">
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'sYsoCorp', 'sTotal']} height={height} limit={11}/>
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">Monet</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['rCustomer', 'rYsoCorp', 'rTotal']} height={height} limit={11}/>
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA - Monet</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sTotal', 'rTotal', 'diffTotal']} height={height}/>
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">ROI Superadmin</div>
          <div className="meta-card-content">
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">ROI</div>
              <ReportLineGraph data={data.ynRoi} is={[]} fields={['roi', 'roiControl']} height={height} verticalLineKeys={[moment.utc()
                .subtract(8, 'days')
                .format('YYYY-MM-DD')]}/>
            </div>
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">Total</div>
              <ReportLineGraph data={data.ynRoi} is={[]} fields={['ua', 'monet', 'profit']} height={height} verticalLineKeys={[moment.utc()
                .subtract(8, 'days')
                .format('YYYY-MM-DD')]}/>
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">Max data (YSO Corp only)</div>
          <div className="meta-card-content">
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">SOV</div>
              <ReportLineGraph data={data.maxYsoSov} is={[]} fields={['sov']} height={height}/>
            </div>
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">Diff Monet</div>
              <ReportLineGraph data={maxDiff} is={[]} fields={['diffPercent']} height={height}/>
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">ROAS</div>
          <div className="meta-card-content">
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">D0</div>
              <ReportLineGraph data={data.ynCampaignRoas} is={[]} fields={['roasD0']} height={height} verticalLineKeys={[moment.utc()
                .subtract(2, 'days')
                .format('YYYY-MM-DD')]}/>
            </div>
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">D7</div>
              <ReportLineGraph data={data.ynCampaignRoas} is={[]} fields={['roasD7']} height={height} verticalLineKeys={[moment.utc()
                .subtract(9, 'days')
                .format('YYYY-MM-DD')]}/>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}
