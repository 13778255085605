import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Steps, Tooltip } from 'antd';
import { DownloadOutlined, GlobalOutlined, PictureOutlined, WarningOutlined } from '@ant-design/icons';

import { campaignsService } from '@/services';
import { MCampaign } from '@/modeles';
import { YcReviewInstall } from '../review-install';
import { YcReviewCountries } from '../review-countries';
import { YcReviewAssets } from '../review-assets';

import './review-popup.scss';


export function YcReviewPopup(props: {
  campaign: MCampaign | null
  cancelReview: () => void;
  validReview: (status: boolean) => void;
}) {
  const [loading, _setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedCampaign, setSelectedCampaign] = useState<MCampaign | null>(null);
  const [avgBids, setAvgBids] = useState<{ platform: string, countryCode: string, avgCpi: number }[] | null>(null);

  useEffect(() => {
    campaignsService.getAvgBids().then((data) => {
      setAvgBids(data);
    });
    //@ts-ignore
  }, [])

  useEffect(() => {
    _setLoading(true)
    setCurrentStep(0);
    if (props.campaign) {
      campaignsService.view(props.campaign.key).then((data) => {
        setSelectedCampaign(data);
        _setLoading(false);
      });
    }
  }, [props.campaign])

  const steps = [
    {
      title: 'First',
      content: <YcReviewAssets campaign={selectedCampaign!} />,
    },
    {
      title: 'Second',
      content: <YcReviewCountries
        campaign={selectedCampaign!}
        _setCampaign={(campaign) => setSelectedCampaign(campaign as MCampaign)}
        avgBids={avgBids?.filter((bid) => bid.platform === selectedCampaign?.Application.platform) || []}/>,
    },
    {
      title: 'Last',
      content: <YcReviewInstall campaign={selectedCampaign!} />,
    },
  ];

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const assetsSubTitle = (): React.ReactNode => {
    const hasUnmoderatedAssets = (assets?: any[]) =>
      assets?.some((asset) => asset.isValide === null);

    const isAssetsEmpty = () =>
      !selectedCampaign?.Videos.length &&
      !selectedCampaign?.Playables.length &&
      !selectedCampaign?.Images.length &&
      !selectedCampaign?.Endcards.length &&
      !selectedCampaign?.Banners.length;

    const tooltips: React.ReactNode[] = [];

    if (
      hasUnmoderatedAssets(selectedCampaign?.Videos) ||
      hasUnmoderatedAssets(selectedCampaign?.Playables) ||
      hasUnmoderatedAssets(selectedCampaign?.Images) ||
      hasUnmoderatedAssets(selectedCampaign?.Endcards) ||
      hasUnmoderatedAssets(selectedCampaign?.Banners)
    ) {
      tooltips.push(
        <Tooltip key="unmoderated" title="One of the creatives has not been moderated">
          <WarningOutlined style={{ color: 'orange', marginRight: 8 }} />
        </Tooltip>
      );
    }

    if (selectedCampaign?.Videos.length === 0 || isAssetsEmpty()) {
      tooltips.push(
        <Tooltip key="missing" title="1 video and 1 creative (playable, endcard or image)">
          <WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      );
    }

    return tooltips.length > 0 ? <>{tooltips}</> : <></>;
  };

  const countriesSubTitle = (): React.ReactNode => {
    if (!selectedCampaign?.countries.find((country) => country.key === 'US')) {
      <Tooltip key="missing" title="US not present in bids countries">
        <WarningOutlined style={{ color: 'red' }} />
      </Tooltip>
    }
    return '';
  };

  const modalTitle = (): React.ReactNode => {
    return (
      <div className='modalTitle'>
        <img src={props.campaign?.Application.Icon?.url} alt="" />
        {props.campaign?.name}
      </div>
    )
  }

  const onEndPopup = (isTunnelFinished: boolean, isValid?: boolean)=> {
    setSelectedCampaign(null);
    if (isTunnelFinished) {
      props.validReview(isValid || false);
    } else {
      props.cancelReview();
    }
  }

  return (
    <Modal title={modalTitle()} open={!!selectedCampaign} onCancel={() => onEndPopup(false)} footer={null} width={1440}>
      <Spin spinning={loading}>
        <Steps
          current={currentStep}
          size="small"
          items={[
            {
              title: 'Assets',
              icon: <PictureOutlined />,
              subTitle: assetsSubTitle(),
            },
            {
              title: 'Countries',
              icon: <GlobalOutlined />,
              subTitle: countriesSubTitle(),
            },
            {
              title: 'Installs',
              icon: <DownloadOutlined />,
              subTitle: '',
            },
          ]}
        />
      </Spin>
      <div>{steps[currentStep].content}</div>
      <div style={{ marginTop: 24, textAlign: 'end' }}>
        {currentStep > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Previous
          </Button>
        )}
        {currentStep < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Next
          </Button>
        )}
        {currentStep === steps.length - 1 && (
          <>
            <Button type="primary" danger onClick={() => onEndPopup(true, false)}>
              Invalidate
            </Button>
            <Button type="primary" onClick={() => onEndPopup(true, true)}>
              Validate
            </Button>
          </>
        )}
      </div>
    </Modal>
  );
}
