import { formatMoney, formatPercent } from '@/components/platform-action-metadata/utils';
import { ReloadOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';

import { ReportLineGraph, YcTitle } from '@/components';
import { usePageTitle } from '@/hooks/use-title';
import { MControlPanel, uniques } from '@/modeles';
import { controlPanelService_SA } from '@/services';

import './control-panel-today.page.scss';

function filterReduceAverageDay<T extends { date: string; }>(data: T[], lastHour: number, property: keyof T, filter?: (e: T) => boolean) {
  return data
    .filter((d) => Number(d.date.split(' ')[1]) < lastHour && (filter ? filter(d) : true))
    .reduce((acc: { [key: string]: number; }, cur) => {
    const day = cur.date.split(' ')[0];
    if (!acc[day]) {
      acc[day] = 0
    }
    acc[day] += (+cur[property]);

    return acc;
  }, {});
}

function filterReduceAverageLastHour<T extends { date: string; }>(data: T[], lastHour: number, property: keyof T, filter?: (e: T) => boolean) {
  return data
    .filter((d) => Number(d.date.split(' ')[1]) === lastHour - 1 && (filter ? filter(d) : true))
    .reduce((acc: { [key: string]: number; }, cur) => {
      const day = cur.date.split(' ')[0];
      if (!acc[day]) {
        acc[day] = 0
      }
      acc[day] += (+cur[property]);

      return acc;
    }, {});
}

function getDay(d: string, todayStr: string, yesterdayStr: string) {
  if (d.includes(todayStr)) {
    return 3;
  }
  if (d.includes(yesterdayStr)) {
    return 2;
  }
  return 1;
}

function dayComparisonDateSorter(today: string, yesterday: string) {
  const todayDayStr = today.split(' ')[0];
  const yesterdayDayStr = yesterday.split(' ')[0];
  return (dayA: string, dayB: string) => {
    const dayANumber = getDay(dayA, todayDayStr, yesterdayDayStr);
    const dayBNumber = getDay(dayB, todayDayStr, yesterdayDayStr);
    if (dayANumber !== dayBNumber) {
      return dayANumber - dayANumber;
    }
    return dayA.localeCompare(dayB);
  };
}

interface DiffIndicatorProps {
  label: string;
  valueFrom: number;
  valueTo: number;
}

const DiffIndicator: React.FC<DiffIndicatorProps> = ({ label, valueFrom, valueTo }) => {
  let diffClass: string = '--red';
  const diff = ((valueTo / valueFrom) - 1);

  if (diff < -0.05) {
    diffClass = '--red';
  } else if (diff < 0.05) {
    diffClass = '--orange';
  } else {
    diffClass = '--green';
  }

  return (
    <div className={'meta-card-graph-diff ' + diffClass}>
      <div className='meta-card-graph-diff-label'>{label}</div>
      <div className='meta-card-graph-diff-percent'>
        {diff > 0
          ? <div>+{formatPercent(diff)}<span className='caret'>▲</span></div>
          : <div>{formatPercent(diff)}<span className='caret'>▼</span></div>}
      </div>
      <div className='meta-card-graph-diff-numbers'>
        {formatMoney(valueFrom)} → {formatMoney(valueTo)}
      </div>
    </div>
  );
};

function ControlPanelTodayTitle<T>({ title, keyD, keyH, average }: {
  title: string,
  keyD: keyof T,
  keyH: keyof T,
  average: T;
}) {
  return (
    <div>
      <span className='meta-card-graph-title'>{title}</span><br/>
      <div className='meta-card-graph-diffs-container'>
        {average[keyD][2] && average[keyD][1] && average[keyD][0] && (
          <div className='meta-card-graph-diffs'>
            <div className='meta-card-graph-diffs-title'>GLOBAL</div>
            <div className='meta-card-graph-diffs-content'>
              <DiffIndicator label='D1' valueFrom={average[keyD][1]} valueTo={average[keyD][2]} />
              <DiffIndicator label='D7' valueFrom={average[keyD][0]} valueTo={average[keyD][2]} />
            </div>
          </div>
        )}
        {average[keyH][2] && average[keyH][1] && average[keyH][0] && (
          <div className='meta-card-graph-diffs'>
            <div className="meta-card-graph-diffs-title">LAST HOUR</div>
            <div className="meta-card-graph-diffs-content">
              <DiffIndicator label="D1" valueFrom={average[keyH][1]} valueTo={average[keyH][2]}/>
              <DiffIndicator label="D7" valueFrom={average[keyH][0]} valueTo={average[keyH][2]}/>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export function SAControlPanelTodayPage() {
  usePageTitle('Control panel today');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MControlPanel>(new MControlPanel());
  const height = 128;
  const todayDay = moment.utc().format('MM-DD 00');
  const yesterdayDay = moment.utc().subtract(1, 'days').format('MM-DD 00');

  function fetchData() {
    setLoading(true);
    controlPanelService_SA.viewToday().then((data) => {
      setData(data || new MControlPanel());
    }).finally(() => {
      setLoading(false);
    });
  }

  useEffect(() => { fetchData() }, []);

  const average = useMemo(() => {
    const today = data.ynYsoVsCustomer.filter((r) => r.date.split(' ')[0] === todayDay.split(' ')[0]);
    const allHours = uniques(today.map((r) => Number(r.date.split(' ').pop() || '0') as number).sort((a, b) => b - a));
    const lastHour = allHours.length > 1 ? allHours[1] : 1;
    const accountFilter = (d: { account: string; }) => d.account === 'global';
    const uaByDay = filterReduceAverageDay(data.ynCustomerUA, lastHour, 'spend', accountFilter);
    const monetByDay = filterReduceAverageDay(data.ynCustomerMonet, lastHour, 'revenue', accountFilter);
    const uaLastHour = filterReduceAverageLastHour(data.ynCustomerUA, lastHour, 'spend', accountFilter);
    const monetLastHour = filterReduceAverageLastHour(data.ynCustomerMonet, lastHour, 'revenue', accountFilter);
    const uaByDayAll = filterReduceAverageDay(data.ynYsoVsCustomer, lastHour, 'sTotal');
    const monetByDayAll = filterReduceAverageDay(data.ynYsoVsCustomer, lastHour, 'rTotal');
    const uaLastHourAll = filterReduceAverageLastHour(data.ynYsoVsCustomer, lastHour, 'sTotal');
    const monetLastHourAll = filterReduceAverageLastHour(data.ynYsoVsCustomer, lastHour, 'rTotal');
    const sov = data.maxYsoRevenue
      .filter((d) => Number(d.date.split(' ')[1]) < lastHour)
      .reduce((acc: {[key: string]: { yso: number; withBanner: number; withoutBanner: number; }}, cur) => {
        const day = cur.date.split(' ')[0];
        if (!acc[day]) {
          acc[day] = {
            yso: 0,
            withBanner: 0,
            withoutBanner: 0,
          }
        }
        acc[day].yso += cur.revenue;
        acc[day].withBanner += cur.totalRevenue;
        acc[day].withoutBanner += cur.totalRevenueNoBanner;

        return acc;
      }, {});

    return {
      uaByDay: Object.entries(uaByDay).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      uaLastHour: Object.entries(uaLastHour).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      monetByDay: Object.entries(monetByDay).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      monetLastHour: Object.entries(monetLastHour).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      uaByDayAll: Object.entries(uaByDayAll).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      monetByDayAll: Object.entries(monetByDayAll).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      uaLastHourAll: Object.entries(uaLastHourAll).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      monetLastHourAll: Object.entries(monetLastHourAll).sort(([kA], [kB]) => dayComparisonDateSorter(todayDay, yesterdayDay)(kA, kB)).map(([, v]) => v),
      sov: Object.entries(sov).map(([, value]) => (
        {
          withBanner: value.yso / value.withBanner,
          withoutBanner: value.yso / value.withoutBanner,
        })),
      lastHour,
    };
  }, [data.maxYsoRevenue, data.ynCustomerMonet, data.ynCustomerUA, data.ynYsoVsCustomer, todayDay, yesterdayDay]);

  const commonGraphProps = { height: height, verticalLineKeys: [yesterdayDay, todayDay], limit: 51 };

  return (
    <Spin spinning={loading}>
      <div id="admin-control-panel">
        <div className="d-flex">
          <YcTitle label={`Control Panel Today 0->${(average.lastHour || 1) - 1}h`}/>
          <Button className="mx-3" type="primary" onClick={() => fetchData()}>
            <ReloadOutlined/> Reload
          </Button>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">Customer</div>
          <div className="meta-card-content">
            <div className="meta-card-graph">
              <ControlPanelTodayTitle title="UA" keyD="uaByDay" keyH="uaLastHour" average={average}/>
              <ReportLineGraph data={data.ynCustomerUA} is={['account']} fields={['spend']} {...commonGraphProps} />
            </div>
            <div className="meta-card-graph">
              <ControlPanelTodayTitle title="Monet" keyD="monetByDay" keyH="monetLastHour" average={average}/>
              <ReportLineGraph data={data.ynCustomerMonet} is={['account']} fields={['revenue']} {...commonGraphProps} />
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA - Monet</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'rCustomer', 'diffYso']} {...commonGraphProps} height={height + 82} />
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">Global</div>
          <div className="meta-card-content">
            <div className="meta-card-graph">
              <ControlPanelTodayTitle title="UA" keyD="uaByDayAll" keyH="uaLastHourAll" average={average}/>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sCustomer', 'sYsoCorp', 'sTotal']} {...commonGraphProps} />
            </div>
            <div className="meta-card-graph">
              <ControlPanelTodayTitle title="Monet" keyD="monetByDayAll" keyH="monetLastHourAll" average={average}/>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['rCustomer', 'rYsoCorp', 'rTotal']} {...commonGraphProps} />
            </div>
            <div className="meta-card-graph">
              <div className="meta-card-graph-title">UA - Monet</div>
              <ReportLineGraph data={data.ynYsoVsCustomer} is={[]} fields={['sTotal', 'rTotal', 'diffTotal']} {...commonGraphProps} height={height + 82} />
            </div>
          </div>
        </div>
        <div className="meta-card">
          <div className="meta-card-title">Android vs iOS</div>
          <div className="meta-card-content">
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">UA</div>
              <ReportLineGraph data={data.ynPlatformRoi} is={[]} fields={['uaAndroid', 'uaIos']} {...commonGraphProps} />
            </div>
            <div className="meta-card-graph --double">
              <div className="meta-card-graph-title">Monet</div>
              <ReportLineGraph data={data.ynPlatformRoi} is={[]} fields={['monetAndroid', 'monetIos']} {...commonGraphProps} />
            </div>
          </div>
        </div>
        <div className="meta-card-graph --simple">
          <div className="meta-card-graph-title">Max SOV (YSO Corp only)</div>
          <ReportLineGraph data={data.maxYsoSov} is={[]} fields={['sov']} {...commonGraphProps} />
        </div>
      </div>
    </Spin>
  );
}
