import { useEffect, useState } from 'react';
import { Button } from 'antd';

import { YcAssetPlayable, YcAssetVideo, YcLoading } from '@/components';
import { adminAssetsService } from '@/services';
import { MCampaign, MCampaignAsset } from '@/modeles';

import './block-validation.scss';

export function YcBlockValidation(props: {
  title: string,
  type: string,
  asset: MCampaignAsset
}) {
  const [asset, _setAsset] = useState<MCampaignAsset | null>(null);
  const [loading, _setLoading] = useState<boolean>(false);

  useEffect(() => {
    _setLoading(true);
    _setAsset(props.asset);
    _setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <YcLoading loading={loading}>
      <div className="block-validation">
        {asset &&
          <>
            {(props.type === "VIDEO" && asset.Document) && <YcAssetVideo controls url={asset.Document.url} />}
            {(props.type === "PLAYABLE" && asset.Document) && <YcAssetPlayable url={asset.Document.url} />}
            {props.type === "ENDCARD" && <img src={asset.Document?.url} className="e-endcard" alt="endcard" />}
            {props.type === "BANNER" && <img src={asset.Document?.url} className="e-banner" alt="banner" />}
            {props.type === "IMAGE" && <img src={asset.Document?.url} className="e-image" alt="imag" />}
            <div className='mt-3'>
              {
                (asset.isValide === null || asset.isValide === false) &&
                <Button type='primary' onClick={async () => {
                  _setLoading(true);
                  _setAsset(await adminAssetsService.validateAndLastToValidate(asset.id + "", props.type));
                  _setLoading(false);
                }}>Validate</Button>
              }
              {
                (asset.isValide === null || asset.isValide === true) &&
                <Button type='default' onClick={async () => {
                  _setLoading(true);
                  _setAsset(await adminAssetsService.devalidateAndLastToValidate(asset.id + "", props.type));
                  _setLoading(false);
                }}>Devalidate</Button>
              }
            </div>
          </>
        }
        {!asset && "No more to check"}
      </div>
    </YcLoading>
  );
}
