import { useMemo, useState } from 'react';
import Table, { ColumnsType, ColumnType, TablePaginationConfig, TableProps } from "antd/lib/table";
import { Col, Row } from 'antd';

import { YcTableHideMenu } from './tableHideMenu';

export interface YcColumnType<T> extends ColumnType<T> {
  ycSort?: ('string' | 'number' | 'numeric');
  ycCanNotHide?: boolean;
  children?: Array<YcColumnType<T>>;
}

export interface YcTableProps<T extends object = any> extends TableProps<T> {
  columns: YcColumnType<T>[],

  ycTableKey: string,
  ycDisableTotal?: boolean,
  ycSummarys?: Array<{
    key: string,
    value?: any,
  }>;
  handleHiddenFieldsChange?: (fields: string[]) => any;
}

export function YcTable<T extends object = any>({columns, ycTableKey, ycSummarys, ycDisableTotal, handleHiddenFieldsChange, ...props}: YcTableProps<T>) {
  const [hiddenFields, setHiddenFields] = useState<string[]>([]);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 100,
    showSizeChanger: true,
    pageSizeOptions: ['50', '100', '200', '500', '1000'],
  });

  const hasFilter = true;

  const columnsNew = useMemo(() => (columns.map((c) => {
    const column = {
      ellipsis: false,
      title: c.dataIndex,
      key: c.key || (c.dataIndex) as string,
      dataIndex: c.dataIndex || c.key,
      ...c,
    };
    // manage sorter
    sortColumn(column);
    if (column.children) {
      column.children.forEach(child => sortColumn(child));
      column.children = column.children.filter(child => !(hiddenFields.indexOf(child.key as string) !== -1));
    }

    return column;
  })).filter(c => !(hiddenFields.indexOf(c.key as string) !== -1)) as ColumnsType<T>, [columns, hiddenFields]);

  function sortColumn(column) {
    if (column.ycSort) {
      const key = (column.dataIndex) as string;
      if (column.ycSort === 'number') {
        column.sorter = (a: any, b: any) => a[key] - b[key];
      } else if (column.ycSort === 'string'){
        column.sorter = (a: any, b: any) => `${a[key]}`.localeCompare(`${b[key]}`);
      } else if (column.ycSort === 'numeric') {
        column.sorter = (a: any, b: any) => `${a[key]}`.localeCompare(`${b[key]}`, undefined, { numeric: true });
      }
    }
  }

  function renderHeader() {
    return (
      <Row justify="end" align="middle" gutter={8}>
        <Col>
          <YcTableHideMenu onChange={(fields) => {
            if (handleHiddenFieldsChange) handleHiddenFieldsChange(fields);
            return setHiddenFields(fields);
          }} tableKey={ycTableKey} columns={columns} />
        </Col>
      </Row>
    );
  };

  let summary: any = undefined;
  if (ycSummarys) {
    summary = () => (<Table.Summary.Row style={{backgroundColor: '#fafafa'}}>
      {ycSummarys.filter(c => !(hiddenFields.indexOf(c.key as string) !== -1)).map(({value, ...rest}, index: number) =>
        <Table.Summary.Cell index={index} {...rest} > {value} </Table.Summary.Cell>
      )}
    </Table.Summary.Row>)
  }

  const x = columnsNew.reduce((sum, column) => {
    const columnWidth = typeof column.width === 'string' ? 0 : column.width || 0;
    return sum + columnWidth;
  }, 0);

  const handleTableChange = (newPagination: TablePaginationConfig) => {
    setPagination({ ...newPagination });
  };

  return (<Table
    dataSource={props.dataSource}
    columns={columnsNew}
    summary={summary}
    pagination={pagination}
    onChange={handleTableChange}
    footer={ycDisableTotal ? () => (
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {hasFilter
          ? <b>{`Total search: ${10} of ${props.dataSource?.length}`}</b>
          : <b>{`Total: ${props.dataSource?.length} `}</b>}
      </div>
    ): undefined}
    title={renderHeader}
    scroll={{ x: x }}
    {...props}
  />)

}
