import { MCampaign } from '@/modeles';
import { CampaignCountries } from '../countries';

import './review-countries.scss';


export function YcReviewCountries(props: {
  campaign: MCampaign
  _setCampaign: React.Dispatch<React.SetStateAction<MCampaign>>,
  avgBids : {platform: string, countryCode: string, avgCpi: number}[]
}) {  
  return (
    <div className='review-countries'>
      <CampaignCountries campaign={props.campaign} _setCampaign={props._setCampaign} avgBids={props.avgBids} />
    </div>
  );
}