import { BaseService } from "./base.service";
import { MCampaign, MCampaignCountry } from '../modeles';
import { SuperAdminTools } from "@/tools";

export class CampaignsService extends BaseService {

  PATH = "advertising/campaigns/";

  getPathSuperadmin() {
    if (SuperAdminTools.isPathSuperAdmin()) {
      return "superadmin/" + this.PATH;
    }
    return this.PATH;
  }

  async listing(isSuperAdmin?: boolean): Promise<MCampaign[] | null> {
    if (isSuperAdmin) {
      return await this._listing(this.getPathSuperadmin() + "listing?superadmin=true");
    }
    return await this._listing(this.getPathSuperadmin() + "listing");
  }
  async add(campaign: MCampaign): Promise<MCampaign | null> {
    return await this._add(this.PATH + "add", campaign);
  }
  async view(key: string): Promise<MCampaign | null> {
    return await this._view(this.PATH + "view/" + key);
  }
  async edit(key: string, app: MCampaign): Promise<MCampaign | null> {
    return await this._edit(this.PATH + "edit/" + key, app);
  }
  async editBudget(key: string, budget: number): Promise<MCampaign | null> {
    return await this._edit<{ budget }, void, MCampaign>(this.PATH + "edit-budget/" + key, { budget });
  }

  async activate(key: string, isActive: boolean): Promise<boolean> {
    return await this._getSuccess(this.getPathSuperadmin() + "activate/" + key + "/" + isActive);
  }

  async validate(key: string): Promise<boolean> {
    return await this._getSuccess(this.getPathSuperadmin() + "validate/" + key);
  }

  async invalidate(key: string): Promise<boolean> {
    return await this._getSuccess(this.getPathSuperadmin() + "invalidate/" + key);
  }

  async isOverCapToday(): Promise<boolean> {
    return await this._view(this.getPathSuperadmin() + "is-over-cap-today") || false;
  }

  async updateTrackingLinks(id: number): Promise<{
    trackingLinkImpression: string;
    trackingLinkClick: string;
  }> {
    const { data } = await this.post(this.PATH + "/" + id + "/updateTrackingLinks", null) || null;

    return data?.trackingLinks;
  }

  async previewStatus(key: string): Promise<{ status: 'valid' | 'warning' | 'error' | null , message: string[] } | null> {
    try {
      const response = await this._view<{
        status: 'valid' | 'warning' | 'error' | null;
        message: string[];
      }>(this.getPathSuperadmin() + `preview-status/${key}`);
      return response || null;
    } catch (error) {
      console.error('Error fetching preview status:', error);
      return null;
    }
  }

  async campaignInstallValidation(key: string): Promise<any | null> {
    const data = await this.get(`${this.getPathSuperadmin()}campaign-install-validation/${key}`, null) || null;
    return data.data;
  }

  async getAvgBids(): Promise<any | null> {
    const data = await this.get(`${this.getPathSuperadmin()}get-avg-bids`, null) || null;
    return data.data
  }

}

export const campaignsService = new CampaignsService();
