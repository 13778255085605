import { Tabs } from 'antd';

import { MCampaign } from '@/modeles';
import { YcBlockValidation } from '../block-validation';

import './review-assets.scss';

export function YcReviewAssets(props: {
  campaign: MCampaign
}) {
  return (
    <div className="review-assets">
      <Tabs defaultActiveKey="1" >
        <Tabs.TabPane tab={`Videos (${props.campaign.Videos.length})`} disabled={props.campaign.Videos?.length === 0} key="1">
          <div className="assets">
            {
              props.campaign.Videos.map((video) => {
                return <YcBlockValidation title="Video" type="VIDEO" asset={video} />
              })
            }
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Playables (${props.campaign.Playables?.length})`} disabled={props.campaign.Playables?.length === 0} key="4">
          <div className="assets">
            {
              props.campaign.Playables.map((playable) => {
                return <YcBlockValidation title="Playable" type="PLAYABLE" asset={playable} />
              })
            }
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Images (${props.campaign.Images?.length})`} disabled={props.campaign.Images?.length === 0} key="2">
          <div className="assets">
            {
              props.campaign.Images.map((image) => {
                return <YcBlockValidation title="Image" type="IMAGE" asset={image} />
              })
            }
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Endcards (${props.campaign.Endcards?.length})`} disabled={props.campaign.Endcards?.length === 0} key="3">
          <div className="assets">
            {
              props.campaign.Endcards.map((endcard) => {
                return <YcBlockValidation title="Encard" type="ENDCARD" asset={endcard} />
              })
            }
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={`Banners (${props.campaign.Banners?.length})`} disabled={props.campaign.Banners?.length === 0} key="5">
          <div className="assets">
            {
              props.campaign.Banners.map((banner) => {
                return <YcBlockValidation title="Banner" type="BANNER" asset={banner} />
              })
            }
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
}
